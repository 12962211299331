import legendclient_emblem from './legendclient-emblem.svg';
import useSound from 'use-sound';
import click from './click.mp3';
import './App.css';

const FollowLink = () => {
  const [play] = useSound(click);

  return <a
      onClick={play}
      className="App-link"
      href="https://twitter.com/LegendClient"
      target="_blank"
      rel={"noreferrer"}
  >
    Follow us for the latest news!
  </a>
};

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={legendclient_emblem} className="App-logo" alt="logo" />
        <p>
          We're still working on things. Check back later...
        </p>
        <FollowLink/>
      </header>
    </div>
  );
}

export default App;
